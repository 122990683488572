import { MEDIQUO_PRO_SUPPORT_EMAIL } from "../../../config/constants";
import { Paragraph, Title } from "./ValidationFailedView";
import AlertIcon from "../../../assets/icons/AlertIcon";
import { useTranslation } from "react-i18next";

const UnknownErrorView = () => {
	const { t } = useTranslation();
	return (
		<div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
			<div className="flex-1 flex flex-col justify-center">
				<div className="container mx-auto max-w-[440px] space-y-6">
					<AlertIcon className="mx-auto w-11 h-11 text-negative" />
					<Title className="mt-1 text-center">
						{t("error__page_unknown_title")}
					</Title>

					<div className="space-y-6">
						<Paragraph>
							{t("error__page_unknown_subtitle")}
							<strong>{MEDIQUO_PRO_SUPPORT_EMAIL}</strong>
						</Paragraph>
						<Paragraph>{t("error__page_unknown_footer")}</Paragraph>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UnknownErrorView;
