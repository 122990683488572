import { useTranslation } from "react-i18next";
import AlertIcon from "../../../assets/icons/AlertIcon";
import { cn } from "../../../utils/cn";

export const Title = ({
	children,
	className,
}: {
	children?: React.ReactNode;
	className?: string;
}) => (
	<h1 className={cn("text-primary font-medium text-2xl", className)}>
		{children}
	</h1>
);

export const Paragraph = ({
	children,
	className,
}: {
	children?: React.ReactNode;
	className?: string;
}) => <p className={cn("text-primary-darker", className)}>{children}</p>;

export const ListItem = ({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) => (
	<li className={cn("text-[#6D658B] list-disc ml-4", className)}>{children}</li>
);

const fields = ["name", "lastname", "age", "id"];

const ValidationFailedView = () => {
	const { t } = useTranslation();

	return (
		<div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
			<div className="flex-1 flex flex-col justify-center">
				<div className="container mx-auto max-w-[440px] space-y-6">
					<AlertIcon className="mx-auto w-11 h-11 text-negative" />
					<Title className="mt-1">
						{t("error__page_validation_failed_title")}
					</Title>

					<div className="space-y-6">
						<Paragraph>{t("error__page_validation_failed_subtitle")}</Paragraph>
						<ul>
							{fields.map((field) => (
								<ListItem key={field}>
									{t(`error__page_validation_failed_item_${field}`)}
								</ListItem>
							))}
						</ul>
						<Paragraph>{t("error__page_validation_failed_footer")}</Paragraph>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ValidationFailedView;
