import { ComponentProps, ReactNode } from "react";
import { cn } from "../../../utils/cn";
import { MEDIQUO_PRO_SUPPORT_EMAIL } from "../../../config/constants";
import { useTranslation } from "react-i18next";

const LogoIcon = (props: ComponentProps<"svg">) => (
	<svg
		width="142"
		height="62"
		viewBox="0 0 142 62"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
		{...props}
	>
		<path
			d="M139.304 51.7034H94.5096L104.939 32.8193L114.337 15.6001L123.735 32.5838L129.819 44.2356C131.026 44.9724 136.432 47.815 137.876 48.4958L128.14 30.5656L114.337 5.09677L100.505 30.9783L86.6877 56.9032H142L139.304 51.7034Z"
			fill="#0D9CD3"
		/>
		<path
			d="M129.819 44.2356H114.675C111.803 44.2356 110.256 43.2926 110.164 40.0816H123.554C123.952 33.6739 120.859 29.2993 114.54 29.2993C113.271 29.2569 112.007 29.4783 110.827 29.9496C109.648 30.4209 108.58 31.1318 107.689 32.0373C106.799 32.9428 106.107 34.0232 105.655 35.2101C105.204 36.3971 105.004 37.6648 105.068 38.933C105.068 44.6775 108.692 48.4932 114.54 48.4932H137.873L129.819 44.2356ZM114.41 32.9814C116.885 32.9814 118.002 33.7332 118.461 36.9291H110.168C110.126 36.3742 110.21 35.8172 110.414 35.2991C110.617 34.7811 110.934 34.3154 111.341 33.9363C111.748 33.5573 112.236 33.2747 112.767 33.1094C113.298 32.9441 113.86 32.9003 114.41 32.9814Z"
			fill="black"
		/>
		<path
			d="M0.00167847 23.6133H15.1869C19.8271 23.6133 21.5354 26.4124 21.5354 30.5656C21.5354 33.8802 20.2097 35.7062 18.5157 36.2667C19.7085 36.7971 20.6366 38.0493 20.7986 40.6855C21.0935 45.6056 21.1962 47.1818 21.9914 48.4339H15.0692C14.7734 47.225 14.6494 45.9804 14.7008 44.7368C14.7008 40.8183 13.7143 40.1259 12.123 40.1259H6.31919V48.4339H0.00167847V23.6133ZM6.31919 34.6604H12.4763C13.949 34.6604 14.8328 33.6146 14.8328 31.9038C14.8328 30.1931 13.949 29.2082 12.4763 29.2082H6.31919V34.6604Z"
			fill="black"
		/>
		<path
			d="M23.9219 23.6133H42.9095V28.9902H30.2402V33.5009H42.1143V38.6716H30.2402V43.1823H42.9095V48.5517H23.9219V23.6133Z"
			fill="black"
		/>
		<path
			d="M44.8834 23.6133H54.1195L58.4506 40.0691L62.8402 23.6133H72.0755V48.5684H66.1832V29.9174L61.0869 48.5684H55.8845L50.8935 29.9174V48.5684H44.8834V23.6133Z"
			fill="black"
		/>
		<path
			d="M75.2422 23.6133H89.2655C94.4211 23.6133 96.2479 27.2052 96.2479 31.9214C96.2479 36.0462 94.6608 40.2294 89.5604 40.2294H81.5614V48.5375H75.2422V23.6133ZM81.5614 35.073H87.1889C88.9431 35.073 89.7074 34.1299 89.7074 31.9356C89.7074 29.7412 88.9414 28.7981 87.1889 28.7981H81.5614V35.073Z"
			fill="black"
		/>
	</svg>
);

const InstructionsTitle = ({ children }: { children: ReactNode }) => (
	<h3 className="mb-4 text-primary text-lg font-medium">{children}</h3>
);

const InstructionsContent = ({
	children,
	className,
}: {
	children: ReactNode;
	className?: string;
}) => (
	<p className={cn("text-primary-darker text-sm leading-4", className)}>
		{children}
	</p>
);

const InstructionsListItem = ({ children }: { children: ReactNode }) => (
	<li className="text-[#6D658B] text-sm font-light leading-4 list-decimal ml-4">
		{children}
	</li>
);

const fields = [
	"Nombre y apellidos",
	"Número de DNI/NIE",
	"Fecha de nacimiento",
	"Número de colegiado (completo, 9 dígitos, comunidad)",
	"Especialidad",
	"Correo electrónico donde quieres recibir los OTP.",
];

const RempeNotValidatedView = () => {
	const { t } = useTranslation();

	return (
		<div className="h-full flex flex-col gap-5 overflow-y-auto pb-10">
			<div className="flex-1 flex flex-col justify-center">
				<div className="container mx-auto max-w-[520px]">
					<LogoIcon className="mx-auto" />

					<div className="mt-12">
						<InstructionsTitle>
							{t("error__page_rempe_not_validated_title")}
						</InstructionsTitle>
						<InstructionsContent>
							{t("error__page_rempe_not_validated_subtitle")}
						</InstructionsContent>

						<InstructionsContent className="mt-4">
							{t("error__page_rempe_not_validated_content", {
								email: MEDIQUO_PRO_SUPPORT_EMAIL,
							})}
						</InstructionsContent>

						<ol className="mt-8">
							{fields.map((field) => (
								<InstructionsListItem key={field}>{field}</InstructionsListItem>
							))}
						</ol>

						<p className="mt-8 text-primary-darker text-sm">
							{t("error__page_rempe_not_validated_description")}
						</p>
					</div>
				</div>
			</div>

			<footer>
				<p className="text-xs text-gray-dark text-center font-light">
					{t("error__page_rempe_not_validated_footer")}
				</p>
			</footer>
		</div>
	);
};

export default RempeNotValidatedView;
