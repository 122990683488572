export const config = {
	services: {
		mediquo: {
			chat_api_url: import.meta.env.VITE_MEDIQUO_CHAT_API_URL,
		},
		osigu: {
			prescription_widget_url: import.meta.env
				.VITE_OSIGU_PRESCRIPTION_SCRIPT_URL,
		},
	},
};
