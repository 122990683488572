import useRempeListener from "../utils/useRempeListener";
import { useEffect } from "react";
import { Contact } from "../services/contacts";
import { toast } from "react-toastify";
import Checked from "../assets/icons/Checked";
import axios from "axios";
import { config } from "../config";
import RempeNotValidatedView from "./eprescription/_components/RempeNotValidatedView";
import { useTranslation } from "react-i18next";

export interface Props {
	onClose: () => void;
	credentials: any;
	consultationType?: string;
	consultationId?: string;
	contact?: Partial<Contact>;
	room?: any;
	jwt: string;
}

const url = config.services.mediquo.chat_api_url;

const RempePrescriptionIframe = ({
	credentials,
	consultationType,
	consultationId,
	room,
	jwt,
}: Props) => {
	const { isEvent, rempeEvent, setIsEvent } = useRempeListener();
	const { t } = useTranslation();

	useEffect(() => {
		if (isEvent && ["REMPE_PRESCRIPTION_UPDATE"].includes(rempeEvent)) {
			setIsEvent(false);
			updateRempe({
				contactId: room?.meta?.contact_id,
				consultationId: consultationId,
				consultationType: consultationType,
				jwt,
			})
				.then(() => {
					toast.success(
						<div className="flex items-center gap-3">
							<Checked />
							{t("toast_component__success")}
						</div>
					);
				})
				.catch(() => {
					toast.error(
						<div className="flex items-center gap-3 bg-negative">
							{t("toast_component__error")}
						</div>
					);
				});
		}
		// eslint-disable-next-line
	}, [isEvent, rempeEvent]);

	// patch fix when registration is not manual
	if (!credentials.meta) return <RempeNotValidatedView />;

	const { meta } = credentials;

	return (
		<div className="flex h-full flex-col">
			<iframe
				id="inlineFrameExample"
				title="Inline Frame Example"
				width="100%"
				height="100%"
				src={meta?.session_url}
				frameBorder="0"
				allowFullScreen
			></iframe>
		</div>
	);
};

const updateRempe = ({
	contactId,
	consultationType,
	consultationId,
	jwt,
}: {
	contactId: string;
	consultationType?: string;
	consultationId?: string;
	jwt: string;
}) =>
	axios.post(
		`${url}/professionals/v1/prescriptions/rempe`,
		{
			contact_id: contactId,
			consultation_type: consultationType,
			consultation_id: consultationId,
		},
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
		}
	);

export default RempePrescriptionIframe;
