import { useTranslation } from "react-i18next";
import PrescriptionPaper from "../assets/images/PrescriptionPaper";

const Success = () => {
	const { t } = useTranslation();
	return (
		<div className="grid content-center h-screen">
			<div className="text-center flex flex-col items-center">
				<PrescriptionPaper className="mb-4" />
				<h1 className="text-primary font-medium text-2xl mb-2">
					{t("success__page_title")}
				</h1>
				<p className="text-dark">{t("success__page_description")}</p>
			</div>
		</div>
	);
};

export default Success;
